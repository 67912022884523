"use client"

import styled, { css, keyframes } from "styled-components"
import {
	desktopCss,
	landscapeTabletCss,
	largeDesktopCss,
	minDesktopCss,
	minTabletCss,
	mobileCss,
	portraitTabletCss,
	pureDesktopCss,
	tabletCss,
} from "./media"

export const Container = styled.div`
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	padding: 0 16px;

	${mobileCss(css`
		max-width: 767px;
	`)}

	${tabletCss(css`
		max-width: 960px;
	`)}

  ${desktopCss(css`
		max-width: 1200px;
	`)}
`

export const HideScrollBars = css`
	&::-webkit-scrollbar {
		display: none;
	}

	& {
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
`

export const PersistScrollBars = css`
	&::-webkit-scrollbar {
		width: 16px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #8b8b8b;
		border-radius: 16px;
		border: 4px solid #fff;
	}

	&::-webkit-scrollbar-thumb:hover {
		border: 3px solid #fff;
	}
`

export const FlexRow = css`
	display: flex;
	flex-flow: row;
	align-items: center;
`

export const FlexColumn = css`
	display: flex;
	flex-flow: column;
`

export const FlexSpacer = styled.div`
	flex-grow: 1;
`

export const TextOverflowEllipses = css`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`

export const CtaClickCommonAnimation = css`
	&:active {
		transform: scale(0.99);
	}
`
type FlexProps = {
	$direction?: "row" | "column" | "row-reverse" | "column-reverse"
	$alignItems?:
		| "center"
		| "flex-start"
		| "flex-end"
		| "baseline"
		| "stretch"
		| "start"
		| "end"
	$justifyContent?:
		| "center"
		| "flex-start"
		| "flex-end"
		| "space-between"
		| "space-around"
		| "space-evenly"
	$gap?: string
	$flex?: number
	$margin?: string
}

export const Flex = styled.div<FlexProps>`
	display: flex;
	flex-direction: ${({ $direction }) => $direction || ""};
	align-items: ${({ $alignItems }) => $alignItems || ""};
	justify-content: ${({ $justifyContent }) => $justifyContent || ""};
	gap: ${({ $gap }) => $gap || ""};
	flex: ${({ $flex }) => $flex || ""};
	margin: ${({ $margin }) => $margin || ""};
`

type GridContainerProps = {
	$backgroundColor?: string
}

export const GridContainer = styled.div<GridContainerProps>`
	width: 100%;
	background-color: ${({ theme, $backgroundColor }) =>
		$backgroundColor || theme.colors.bg.section.default};
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: max-content;
	column-gap: 16px;
	margin: 0 auto;
	justify-content: center;

	${minTabletCss(css`
		grid-template-columns: repeat(8, 1fr);
	`)}

	${portraitTabletCss(css`
		column-gap: 56px;
	`)}

    ${landscapeTabletCss(css`
		grid-template-columns: repeat(8, 64px);
		column-gap: 48px;
	`)}

    ${pureDesktopCss(css`
		column-gap: 56px;
		padding: 0 200px;
	`)}

    ${largeDesktopCss(css`
		grid-template-columns: repeat(8, 80px);
		column-gap: 56px;
	`)}
`

type GridItemProps = {
	$col?: number
	$useGridPadding?: boolean
}
export const GridItemContainer = styled.div<GridItemProps>`
	grid-column: span ${({ $col }) => $col || 4};

	${mobileCss(css<GridItemProps>`
		padding: ${({ $useGridPadding }) => ($useGridPadding ? "0 24px" : "0")};
	`)}

	${portraitTabletCss(css<GridItemProps>`
		grid-column: span 8;
		padding: ${({ $useGridPadding }) => ($useGridPadding ? "0 32px" : "0")};
	`)}

	${minDesktopCss(css<GridItemProps>`
		grid-column: span ${({ $col }) => $col || 8};
	`)}
`

export const Shake = keyframes`
	 0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
  80% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  90% {
    -webkit-transform: translateX(-4px);
            transform: translateX(-4px);
  }
`

export const HideArrowsForNumberInput = css`
	-moz-appearance: textfield;
	appearance: textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		margin: 0;
	}
`
