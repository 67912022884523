import styled, { css } from "styled-components"
import { minDesktopCss } from "./media"

type TextProps = {
	$mobColor?: string
	$webColor?: string
}

export const NavRegularTextCss = css`
	/* Mob/Nav/Regular (10) */
	font-family: var(--font-inter);
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;

	${minDesktopCss(css`
		/* Web/Nav/Regular (16) */
		font-size: 16px;
		line-height: 20px;
	`)}
`

export const NavRegularText = styled.span<TextProps>`
	${NavRegularTextCss}

	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};
	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
	`)}
`

export const NavMediumText = styled.span<TextProps>`
	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};

	/* Mob/Nav/Medium (10) */
	font-family: var(--font-inter);
	font-size: 10px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;

	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
		/* Web/Nav/Medium (16) */
		font-size: 16px;
		line-height: 20px;
	`)}
`

export const LabelRegularTextCss = css`
	/* Label/Regular (12) - Same in Mob and Web */
	font-family: var(--font-inter);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`

export const LabelRegularText = styled.span<TextProps>`
	${LabelRegularTextCss}

	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};
	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
	`)}
`

export const LabelSemiBoldTextCss = css`
	/* Label/SemiBold (12) - Same in Mob and Web */
	font-family: var(--font-inter);
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
`
export const LabelSemiBoldText = styled.span<TextProps>`
	${LabelSemiBoldTextCss}

	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};
	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
	`)}
`

// same as semibold, figma issue shows diff names
export const LabelHeavyTextCss = css`
	${LabelSemiBoldTextCss}
`
export const LabelHeavyText = styled(LabelSemiBoldText)``

export const BodySmallTextCss = css`
	/* Mob/Body/Small (12) */
	font-family: var(--font-inter);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	margin: 0px;

	${minDesktopCss(css`
		/* Web/Body/Medium (14) */
		font-size: 14px;
	`)}
`

export const BodySmallText = styled.p<TextProps>`
	${BodySmallTextCss}

	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};

	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
	`)}
`

export const BodyMediumTextCss = css`
	/* Body/Medium (14) - Same in Mob and Web */
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	margin: 0px;
`
export const BodyMediumText = styled.p<TextProps>`
	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};

	${BodyMediumTextCss}

	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
	`)}
`

export const ButtonDefaultText = styled.span<TextProps>`
	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};

	/* Mob/Button/Default (14) */
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;

	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
		/* Web/Button/Default (16) */
		font-size: 16px;
		line-height: 24px;
	`)}
`

export const ButtonUnderlinedSmallText = styled.span<TextProps>`
	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};

	/* Mob/Button/Underline/Small (12) */
	font-family: var(--font-inter);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	text-decoration-line: underline;

	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
		/* Web/Button/Underline/Default */
		font-size: 14px;
	`)}
`

export const ButtonUnderlinedMediumText = styled.span<TextProps>`
	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};

	/* Button/Underline/Medium (14) - Same in Mob and Web */
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	text-decoration-line: underline;

	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
	`)}
`

export const HeadingSansSerifSmallTextCss = css`
	/* Mob/Heading/Sans-serif/Small (14) */
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	margin: 0px;

	${minDesktopCss(css`
		/* Web/Heading/Sans-serif/Small(16) */
		font-size: 16px;
	`)}
`

export const HeadingSansSerifSmallText = styled.h3<TextProps>`
	${HeadingSansSerifSmallTextCss}

	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};
	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
	`)}
`

export const HeadingSansSerifMediumTextCss = css`
	/* Mob/Heading/Sans-serif/Medium (16) */
	font-family: var(--font-inter);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	margin: 0px;

	${minDesktopCss(css<TextProps>`
		/* Web/Heading/Sans-serif/Medium (20) */
		font-size: 20px;
		line-height: 32px;
	`)}
`

export const HeadingSansSerifMediumText = styled.h2<TextProps>`
	${HeadingSansSerifMediumTextCss}

	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};
	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
	`)}
`

export const HeadingSansSerifLargeTextCss = css`
	/* Mob/Heading/Sans-serif/Large (20) */
	font-family: var(--font-inter);
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;

	${minDesktopCss(css<TextProps>`
		/* Web/Heading/Sans-serif/Large (24) */
		font-size: 24px;
	`)}
`

export const HeadingSansSerifLargeText = styled.h1<TextProps>`
	${HeadingSansSerifLargeTextCss}

	margin: 0px;

	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};
	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
	`)}
`

export const HeadingSansSerifExtraLargeTextCss = css`
	/* Heading/Sans-serif/XLarge (32) - Same in Mob and Web */
	font-family: var(--font-inter);
	font-size: 32px;
	font-style: normal;
	font-weight: 600;
	line-height: 40px;

	${minDesktopCss(css<TextProps>`
		/* Web/Heading/Sans-serif/Large (24) */
		font-size: 24px;
	`)}
`

export const HeadingSansSerifExtraLargeText = styled.h1<TextProps>`
	${HeadingSansSerifExtraLargeTextCss}

	margin: 0px;

	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};
	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
	`)}
`

export const HeadingSerifMediumTextCss = css`
	/* Mob/Heading/Serif/Medium (16) */
	font-family: var(--font-lora);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0.32px;
	margin: 0px;

	${minDesktopCss(css<TextProps>`
		/* Web/Heading/Serif/Medium (20) */
		font-size: 20px;
		line-height: 32px;
		letter-spacing: unset;
	`)}
`

export const HeadingSerifMediumText = styled.h2<TextProps>`
	${HeadingSerifMediumTextCss}

	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};
	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
	`)}
`

export const HeadingSerifLargeTextCss = css`
	/* Mob/Heading/Serif/Large (20) */
	font-family: var(--font-lora);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px;
	margin: 0px;

	${minDesktopCss(css<TextProps>`
		/* Web/Heading/Serif/Large (24) */
		font-size: 24px;
		font-weight: 500;
	`)}
`

export const HeadingSerifLargeText = styled.h1<TextProps>`
	${HeadingSerifLargeTextCss}

	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};
	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
	`)}
`

export const NumberSmallText = styled.span<TextProps>`
	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};

	/* Number/Small */
	font-family: var(--font-space-grotesk);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;

	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
		font-size: 20px;
		line-height: 32px;
	`)}
`

export const NumberMediumText = styled.span<TextProps>`
	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};

	/* Number/Medium */
	font-family: var(--font-space-grotesk);
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 32px;

	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
	`)}
`

export const NumberLargeTextCss = css`
	/* Number/large */
	font-family: var(--font-space-grotesk);
	font-size: 48px;
	font-style: normal;
	font-weight: 500;
	line-height: 62px;
`
export const NumberLargeText = styled.span<TextProps>`
	${NumberLargeTextCss}

	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};
	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
	`)}
`

export const NumberDefaultText = styled.span<TextProps>`
	color: ${({ theme, $mobColor }) => $mobColor || theme.colors.text.primary};

	/* Number/default */
	font-family: var(--font-space-grotesk);
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 32px;

	${minDesktopCss(css<TextProps>`
		color: ${({ theme, $webColor }) => $webColor || theme.colors.text.primary};
		font-size: 24px;
	`)}
`
