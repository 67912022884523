import { breakpoints } from "@common/theme"
import { css } from "styled-components"

// css for all dims from mobile
export const minMobileCss = (styles: any) => css`
	@media (min-width: ${`${breakpoints.mobile.min}px`}) {
		${styles}
	}
`

// css for mobile only
export const mobileCss = (styles: any) => css`
	@media (min-width: ${`${breakpoints.mobile.min}px`}) and (max-width: ${`${breakpoints.tablet.min}px`}) {
		${styles}
	}
`

// use for mobile and tablet portrait. use desktopCss as counter part to render desktop from tablet landscape
export const mobileTabletCss = (styles: any) => css`
	@media (max-width: ${`${breakpoints.tabletLandscape.min}px`}) {
		${styles}
	}
`

// css for all dims from tablet
export const minTabletCss = (styles: any) => css`
	@media (min-width: ${`${breakpoints.tablet.min}px`}) {
		${styles}
	}
`

// tablet css triggers from mobile till tablet because we don't have tablet specific css right now, and we render mobile for tablet as well
export const tabletCss = (styles: any) => css`
	@media (min-width: ${`${breakpoints.mobile.min}px`}) and (max-width: ${`${breakpoints.desktop.min}px`}) {
		${styles}
	}
`

// css for tablet only dims
export const tabletOnlyCss = (styles: any) => css`
	@media (min-width: ${`${breakpoints.tablet.min}px`}) and (max-width: ${`${breakpoints.desktop.min}px`}) {
		${styles}
	}
`

// css for portrait tablet only dims
export const portraitTabletCss = (styles: any) => css`
	@media (min-width: ${`${breakpoints.tablet.min}px`}) and (max-width: ${`${breakpoints.tabletLandscape.min}px`}) {
		${styles}
	}
`

// css for landscape tablet only dims
export const landscapeTabletCss = (styles: any) => css`
	@media (min-width: ${`${breakpoints.tabletLandscape.min}px`}) and (max-width: ${`${breakpoints.desktop.min}px`}) {
		${styles}
	}
`

export const pureDesktopCss = (styles: any) => css`
	@media (min-width: ${`${breakpoints.desktop.min}px`}) and (max-width: ${`${breakpoints.desktop.max}px`}) {
		${styles}
	}
`

// css for all dims from desktop
export const minDesktopCss = (styles: any) => css`
	@media (min-width: ${`${breakpoints.tabletLandscape.min}px`}) {
		${styles}
	}
`

// css for desktop only
export const desktopCss = (styles: any) => css`
	@media (min-width: ${`${breakpoints.tabletLandscape.min}px`}) and (max-width: ${`${breakpoints.desktop.max}px`}) {
		${styles}
	}
`

// css for dims from large desktop only
export const minLargeDesktopCss = (styles: any) => css`
	@media (min-width: ${`${breakpoints.largeDesktop.min}px`}) {
		${styles}
	}
`

// css for largeDesktop only dims
export const largeDesktopCss = (styles: any) => css`
	@media (min-width: ${`${breakpoints.largeDesktop.min}px`}) and (max-width: ${`${breakpoints.largeDesktop.max}px`}) {
		${styles}
	}
`
